import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlayerProService {

  static readonly PLAYER_VERSION_DATE_REGEX = /\d{4}\.\d{2}\.\d{2}\.*/;
  static readonly SCREENSHOT_PLAYER_VERSION = '2017.01.10.17.33';
  static readonly DISPLAY_CONTROL_PLAYER_VERSION = '2018.01.15.16.31';
  static readonly CHROMEOS_PLAYER_VERSION = '2018.07.20.10229';
  static readonly CHROMEOS_SCREENSHOT_PLAYER_VERSION = '2018.08.17.8388';

  private _compareVersion (minimumVersion, currentVersion) {
    return PlayerProService.PLAYER_VERSION_DATE_REGEX.test(currentVersion) && currentVersion >= minimumVersion;
  }

  isCROSLegacy (display?) {
    const os = (display && display.os || '').toLowerCase();

    return (os.indexOf('cros') !== -1 && os.indexOf('microsoft') === -1);
  }

  isOldElectronPlayer (display?) {
    return !!(display && display.playerName &&
      display.playerName.indexOf('RisePlayerElectron') !== -1);
  }

  isChromeOSPlayer (display?) {
    return !!(display && display.playerName && display.playerName.indexOf('RisePlayer') !== -1 &&
      !this.isOldElectronPlayer(display) && this._compareVersion(PlayerProService.CHROMEOS_PLAYER_VERSION, display.playerVersion));
  }

  isWebPlayer (display?) {
    return !!(display && display.playerName &&
      display.playerName.indexOf('Web Player') !== -1);
  }

  isAndroidPlayer (display?) {
    return !!(display && display.playerName &&
      display.playerName.indexOf('Android Player') !== -1);
  }

  isFireOSPlayer (display?) {
    return this.isAndroidPlayer(display) && display.buildType === 'amazon-store';
  }

  isChromeOSAppPlayer (display?) {
    return !!(display && display.playerName &&
      display.playerName.indexOf('chromeos-app-player') !== -1);
  }

  isAppleTVPlayer (display?) {
    return !!(display && display.playerName &&
      display.playerName.indexOf('Apple TV Player') !== -1);
  }

  isElectronPlayer (display?) {
    return !!(display && display.playerName &&
      (display.playerName.indexOf('Windows Player') !== -1 || display.playerName.indexOf('Electron Player') !== -1));
  }

  isChromecastGoogleTVProduct (display?) {
    return !!(display && display.productName && display.productName.startsWith("Chromecast"));
  }

  isGiadaDN74Product (display?) {
    return !!(display && display.productName === 'jhs558');
  }

  isScreenshotCompatiblePlayer (display?) {
    const oldElectronSupported = this.isOldElectronPlayer(display) && this._compareVersion(PlayerProService.SCREENSHOT_PLAYER_VERSION,
      display.playerVersion);
    const chromeOSSupported = this.isChromeOSPlayer(display) && this._compareVersion(
      PlayerProService.CHROMEOS_SCREENSHOT_PLAYER_VERSION, display.playerVersion);
    const androidSupported = this.isAndroidPlayer(display);
    const chromeOSAppSupported = this.isChromeOSAppPlayer(display);
    const electronSupported = this.isElectronPlayer(display);

    return oldElectronSupported || chromeOSSupported || chromeOSAppSupported || androidSupported || electronSupported;
  }

  isDeviceOrientationCompatiblePlayer (display?) {
    const chromeOSSupported = this.isCROSLegacy(display) || this.isChromeOSPlayer(display) || this.isChromeOSAppPlayer(display);
    const androidSupported = this.isAndroidPlayer(display) && !this.isFireOSPlayer(display) && !this.isChromecastGoogleTVProduct(display) && !this.isAvocorRSeriesDisplay(display);

    return chromeOSSupported || androidSupported;
  }

  isDisplayControlCompatiblePlayer (display?) {
    if (!display) {
      return false;
    }

    const oldElectronSupported = this.isOldElectronPlayer(display) &&
      this._compareVersion(PlayerProService.DISPLAY_CONTROL_PLAYER_VERSION, display.playerVersion);
    const electronSupported = this.isElectronPlayer(display);
    const androidSupported = this.isAndroidPlayer(display) &&
      ( this.isGiadaDN74Product(display) || this.isAvocorRSeriesDisplay(display) );

    return (oldElectronSupported || electronSupported || androidSupported) && display.playerProAuthorized;
  }

  isRebootCompatiblePlayer (display?) {
    if (!display) {return false;}

    const chromeOSSupported = this.isCROSLegacy(display) || this.isChromeOSPlayer(display) || this.isChromeOSAppPlayer(display);
    const electronSupported = this.isElectronPlayer(display);

    if (display.productName === 'X10-Mini') {return true;}

    if (this.isGiadaDN74Product(display)) {return true;}

    if (this.isAvocorRSeriesDisplay(display)) {return true;}

    if (this.isAndroidPlayer(display)) {return false;}

    if (this.isWebPlayer(display)) {return false;}

    if (this.isAppleTVPlayer(display)) {return false;}

    // Cannot reboot Linux/Windows/Mac PackagedApp players
    return chromeOSSupported || electronSupported || display.playerName !== 'RisePlayerPackagedApp';
  }

  isRestartCompatiblePlayer (display?) {
    if (!display) {return false;}

    if (this.isWebPlayer(display)) {return false;}

    if (this.isAppleTVPlayer(display)) {return false;}

    return true;
  }

  isRebootOrRestartDailyCompatiblePlayer (display?) {
    if (!display) {return false;}

    if (this.isWebPlayer(display)) {return false;}

    if (this.isAvocorDisplay(display)) {return false;}

    if (this.isAppleTVPlayer(display)) {return false;}

    return true;
  }

  isScreenSharingCompatiblePlayer (display?) {
    if (!display) {return false;}

    if (this.isAppleTVPlayer(display)) {return false;}

    return true;
  }

  isAvocorDisplay (display?) {
    return !!display && display.buildType === 'avocor';
  }

  isAutostartFlagEnabledPlayer (display?) {
    return this.isAndroidPlayer(display) && !this.isAvocorDisplay(display);
  }

  isAvocorRSeriesDisplay(display?) {
    return !!(display && ['smith', 'AVR-4310', 'AVR-5510'].includes(display.productName));
  }
}
